<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h3 class="title">{{ label }}</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-gudang">Gudang </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ label_path }}
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="validated == 1 && label_path == 'Edit'"
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div
              v-show="
                (validated != 1 && label_path == 'Edit') ||
                label_path == 'Tambah'
              "
            >
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>
          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">Data Gudang</div>
                <div class="sub-title">Tambah informasi yang diperlukan</div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Nama Gudang : </label>
                  <input
                    type="text"
                    :disabled="validated == 1 && label_path == 'Edit'"
                    class="form-control"
                    v-model="formData.nama"
                    @keyup="formChange('nama')"
                    :class="{ 'is-invalid': formError && formError.nama }"
                    id="inputAddress2"
                    placeholder="Masukkan Nama Gudang"
                  />
                  <div class="form-error" v-if="formError && formError.nama">
                    {{ formError.nama }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Status</label>
                  <Select2
                    :disabled="validated == 1 && label_path == 'Edit'"
                    v-model="formData.status"
                    :options="statusCompany"
                    placeholder="Status Gudang"
                    :settings="{ minimumResultsForSearch: -1 }"
                    :class="{ 'is-invalid': formError && formError.status }"
                    @change="myChangeEvent($event, 'status')"
                    @select="myChangeEvent($event)"
                  />
                  <div class="form-error" v-if="formError && formError.status">
                    {{ formError.status }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="title">Alamat Gudang</div>
                <div class="sub-title">
                  Tambah alamat gudang yang diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Provinsi :</label>
                  <Select2
                    :disabled="validated == 1 && label_path == 'Edit'"
                    v-model="formData.id_provinsi"
                    :options="OptionsProvince"
                    placeholder="Provinsi"
                    :class="{
                      'is-invalid': formError && formError.id_provinsi,
                    }"
                    @change="locationEvent($event, 'id_provinsi')"
                    @select="locationEvent($event, 'id_provinsi')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_provinsi"
                  >
                    {{ formError.id_provinsi }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress2">Kabupaten :</label>
                  <Select2
                    :disabled="validated == 1 && label_path == 'Edit'"
                    v-model="formData.id_kabupaten"
                    :options="OptionsRegion"
                    placeholder="Kabupaten"
                    :settings="{ settingOption: value, settingOption: value }"
                    :class="{
                      'is-invalid': formError && formError.id_kabupaten,
                    }"
                    @change="locationEvent($event, 'id_kabupaten')"
                    @select="locationEvent($event, 'id_kabupaten')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_kabupaten"
                  >
                    {{ formError.id_kabupaten }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="inputAddress2">Kecamatan :</label>
                  <Select2
                    :disabled="validated == 1 && label_path == 'Edit'"
                    v-model="formData.id_kecamatan"
                    :options="OptionsDistrict"
                    placeholder="Kecamatan"
                    :settings="{ settingOption: value, settingOption: value }"
                    :class="{
                      'is-invalid': formError && formError.id_kecamatan,
                    }"
                    @change="locationEvent($event, 'id_kecamatan')"
                    @select="locationEvent($event, 'id_kecamatan')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_kecamatan"
                  >
                    {{ formError.id_kecamatan }}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Lokasi :</label>
                  <textarea
                    type="text"
                    :disabled="validated == 1 && label_path == 'Edit'"
                    v-model="formData.alamat"
                    :class="{ 'is-invalid': formError && formError.alamat }"
                    @keyup="formChange('alamat')"
                    class="form-control"
                    id="inputAddress2"
                    placeholder="Masukkan Lokasi"
                  ></textarea>
                  <div class="form-error" v-if="formError && formError.alamat">
                    {{ formError.alamat }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
import Select2 from "vue3-select2-component";
import SidebarDashboard from "../../../../src/components/Sidebar.vue";
import NavbarDashboard from "../../../../src/components/Navbar.vue";
import { loadScript } from "vue-plugin-load-script";
loadScript("/assets/js/stisla.js");
loadScript("/assets/js/scripts.js");
loadScript("/assets/js/custom.js");
loadScript("/assets/js/page/index.js");

import {
  get_ListKabupaten,
  get_ListKecamatan,
  get_ListProvinsi,
} from "../../../actions/master";
import { checkRules, cksClient, showAlert } from "../../../helper";
import {
  get_GudangDetail,
  post_GudangSave,
} from "../../../actions/company/gudang";

export default {
  name: "DashboardLayout",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
  },

  data() {
    return {
      validated: 1,
      myValue: "aaaa",
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      optionPeriode: ["1 Bulan", "2 Bulan"],
      OptionsRegion: [],
      OptionsDistrict: [],
      OptionsProvince: [],
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
      id_gudang: this.$route.params.id ? this.$route.params.id : "",
      label: "Tambah Gudang",
      label_path: "Tambah",
      formData: {
        id: this.$route.params.id ? this.$route.params.id : "",
        id_company: cksClient().get("_account").id_company,
        id_divisi: cksClient().get("_account").id_divisi,
        nama: "",
        alamat: "",
        id_kecamatan: "",
        id_kabupaten: "",
        id_provinsi: "",
        status: "aktif",
      },
      formError: [],
      rules: {
        nama: {
          required: true,
        },
        alamat: {
          required: true,
        },
        id_kecamatan: {
          required: true,
        },
        id_kabupaten: {
          required: true,
        },
        id_provinsi: {
          required: true,
        },
        status: {
          required: true,
        },
      },
      isSubmit: false,
    };
  },
  async created() {
    if (this.id_gudang) {
      this.label = "Edit gudang";
      this.label_path = "Edit";
      this.getGudang();
    } else {
      this.label = "Tambah Gudang";
      this.label_path = "Tambah";
    }
    this.getProvinsi();
  },
  methods: {
    editable() {
      this.validated = 2;
    },
    clickCancel() {
      this.$router.push({ name: "dataWerehouse" });
    },
    optionTemplete(row) {
      console.log(row);
      return row;
    },
    async getGudang() {
      await get_GudangDetail(this.id_gudang, (res) => {
        this.formData.id = res.id;
        this.formData.nama = res.nama;
        this.formData.status = res.status;
        this.formData.id_company = res.id_company;
        this.formData.id_divisi = res.id_divisi;
        this.formData.id_provinsi = res.id_provinsi;
        this.getKabupaten(res.id_provinsi);
        this.formData.id_kabupaten = res.id_kabupaten;
        this.getKecamatan(res.id_kabupaten);
        this.formData.id_kecamatan = res.id_kecamatan;
        this.formData.alamat = res.alamat;
      });
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    async getProvinsi() {
      await get_ListProvinsi(
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsProvince = arr;
        },
        () => {
          this.OptionsProvince = [];
        }
      );
    },
    async getKabupaten(id) {
      await get_ListKabupaten(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsRegion = arr;
        },
        () => {
          this.OptionsRegion = [];
        }
      );
    },

    async getKecamatan(id) {
      await get_ListKecamatan(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsDistrict = arr;
        },
        () => {
          this.OptionsDistrict = [];
        }
      );
    },
    locationEvent(val, key) {
      this.formChange(key);
      if (key === "id_provinsi") {
        this.OptionsDistrict = [];
        this.OptionsRegion = [];
        this.getKabupaten(val.id);
      } else if (key === "id_kabupaten") {
        this.OptionsDistrict = [];
        this.getKecamatan(val.id);
      }
    },
    myChangeEvent(val, key) {
      console.log(val);
      this.formChange(key);
    },
    postData() {
      this.isSubmit = true;
      post_GudangSave(
        this.formData,
        (res) => {
          this.isSubmit = false;
          var msg = "";
          if (res.response_code === 201) {
            msg = "Data gudang berhasil ditambahkan";
          } else {
            msg = "Data gudang berhasil diperbarui";
          }
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            showCancelButton: false,
            showConfirmButton: true,
            onSubmit: () => {
              this.$router.push({ name: "dataWerehouse" });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}
</style>
